import * as React from "react";
import Layout from "../components/Layout";

const PrivacyPolicyPage = () => (
  <Layout>
    <div>
    <section className="section content doc">
    <h1>Privacy Policy for One a Day</h1>
        <p>One a Day is an Ad Supported app built by Klaudia Bronowicka. This SERVICE is provided by Klaudia Bronowicka at no cost and is intended for use as is. One of my main priorities in regards to One a Day is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by One a Day and how we use it. By using this service, you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.</p>
        <p>If you have additional questions or require more information about this Privacy Policy, do not hesitate to email me at <a href="mailto:hello@oneadayapp.com" target="_blank" rel="noopener">hello@oneadayapp.com</a>.</p>
        <h1>Log Files</h1>
        <p>One a Day follows a standard procedure of using log files. These files log visitors when they use the app, or in case of an app error. The information collected by log files include internet protocol (IP) address, device name, operating system version, the configuration of the app when utilising my Service, the time and date of your use of the Service, and other statistics. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the app, tracking users' movement on the app, and gathering demographic information.</p>
        <h1>Cookies</h1>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
        <p>This Service does not use these &ldquo;cookies&rdquo; explicitly. However, the app may use third-party code and libraries that use &ldquo;cookies&rdquo; to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
        <h1>Service Providers</h1>
        <p>I may employ third-party companies and individuals due to the following reasons:</p>
        <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>I want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
        <h1>Security</h1>
        <p>I value your trust in providing us your Personal Information, thus I am striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.</p>
        <h1>Links to Other Sites</h1>
        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
        <h1>Our Advertising Partners</h1>
        <p>Some advertisers in One a Day app may use cookies and web beacons that are used in their respective advertisements and links that appear in One a Day. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalise the advertising content that you see on this app or other apps or websites.</p>
        <p>Note that One a Day has no access to or control over these cookies that are used by third-party advertisers. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
        <ul>
        <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener">AdMob</a></li>
        </ul>
        <p>One a Day's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
        <h1>Children's Information</h1>
        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.&nbsp;</p>
        <p>One a Day is not addressed to and does not knowingly collect any Personal Identifiable Information from children under the age of 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you think that your child provided this kind of information on our App, I strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
        <h1>Online Privacy Policy Only</h1>
        <p>This Privacy Policy applies only to our online activities and is valid for visitors to our App with regards to the information that they shared and/or collected in One a Day. This policy is not applicable to any information collected offline or via channels other than this app.</p>
        <h1>Consent</h1>
        <p>By using One a Day app, you hereby consent to this Privacy Policy and agree to its <a href="/terms">Terms and Conditions</a>.</p>
        <h1>Changes to This Privacy Policy</h1>
        <p>I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. App users will be notified of the changes by email.</p>
        <p>This policy is effective as of 2022-09-01</p>
        <h1>Contact Us</h1>
        <p>If you have any questions or suggestions about this Privacy Policy, do not hesitate to contact me at <a href="mailto:hello@oneadayapp.com" target="_blank" rel="noopener">hello@oneadayapp.com</a>.</p>
    </section>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;
